import axios from '@/axios';

export default {
    fetchAccountLevels({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.get('/api/v1/admins/account-levels')
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },
    createAccountLevel({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.post('/api/v1/admins/account-levels/create', data)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },
    updateAccountLevel({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.patch(`/api/v1/admins/account-levels/update/${data._id}`, data)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    },
    deleteAccountLevel({ commit }, data) {
        return new Promise((resolve, reject) => {
            axios.patch(`/api/v1/admins/account-levels/delete/${data._id}`, data)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err)
            });
        })
    }
}
